<template>
  <div class="flex flex-col items-center">
    <div class="max-w-5xl lg:w-full w-5/6 lg:mx-0 mx-auto">

      <h2 class="text-2xl font-semibold py-4 border-b-2 border-red-600">
        Pay Trends
      </h2>
      <img
        src="../../assets/img/home_money_plant.jpg"
        alt="Pay Trends Banner"
        class="w-full lg:h-96 h-80 mt-4 object-cover"
      >
      <br>
      <br>
      <div>
        AIMS Pay Trends provides fresh graduates
        start rates, worldwide minimum wage and
        living wage by country/region/city.
      </div>
      <br>
      <br>
      <div class="text-center font-bold mt-4">
        Fresh graduates start rates by
        country/region and by discipline.
      </div>
      <br>
      <div class="flex flex-wrap justify-center lg:gap-16 gap-10">
        <img
          src="../../assets/img/tools_paytrend1_1.png"
          alt="Expatriate Practice Banner"
          class="w-full"
        >
        <!-- <img
          src="../../assets/img/tools_paytrend2.png"
          alt="Expatriate Practice Banner"
          class="w-full"
        > -->
      </div>
      <br>
      <div class="text-center font-bold mt-12">
        Minimum wage and living wage in different locations.
      </div>
      <br>
      <div class="flex flex-wrap justify-center lg:gap-16 gap-10">
        <!-- <img
          src="../../assets/img/tools_paytrend_minwage.png"
          alt="Expatriate Practice Banner"
          class="w-full"
        > -->
        <img
          src="../../assets/img/tools_paytrend_livingwage.png"
          alt="Expatriate Practice Banner"
          class="w-full"
        >
      </div>
      <br>
    </div>
  </div>
</template>

<style scoped>

</style>

<script>
export default {};
</script>
